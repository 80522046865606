import React, { useState } from 'react';

import { useNavigate, useLocation, Link } from 'react-router-dom';

import './Navbar.css';

export default function Navbars(props) {
    
  const { navbar } = props;

  const [click, setClick] = useState(false);

  const location = useLocation();

  const handleClick = () => {
    setClick(!click);
  }
  
  return <nav id='Navbar' className={ 'navcontainer ' + navbar}>
    <div name='/' className='logo' onClick={props.onClick}>
      <span>Sand</span>Palace
    </div>

    <div className='menu-icon' onClick={handleClick}>
      <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
    </div>

    {/*<div className={navbar ? 'nav-submenu active' : 'nav-submenu'}>
      <div style={{textAlign: 'left'}}>Offices&nbsp;&nbsp;<span>
        <i className='fas fa-caret-down' />
      </span></div>
      <div>Academia</div>
      <div>Careers</div>
      <div>Suscribe</div>
      <div style={{borderRight: 'none'}}>Contact</div>
    </div>*/}

    <div className={click ? 'nav-menu active' : 'nav-menu'}>
      <Link 
        to='/'
        className={ !location.hash ? "nav-links active" : "nav-links" }
      >
        <span>About</span>
      </Link>
      
      <Link 
        to='#howto'
        className={ location.hash === '#howto' ? "nav-links active" : "nav-links" }
      >
        <span>How to?</span>
      </Link>
      
      <Link 
        to='#cases'
        className={ location.hash === '#cases' ? "nav-links active" : "nav-links" }
      >
        <span>Use cases</span>
      </Link> 
      
      <Link 
        id="nav-links-last" 
        className={ location.hash === '#tools' ? "nav-links active" : "nav-links" }
        to='#tools'
      >
        <span>Our tools</span>
      </Link>

    </div>

  </nav>
};
