import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom'

import Navbar from './Navbar';
import Header from './Header';
import HowTo from './HowTo';
import About from './About';
import Tools from './Tools';
import Footer from '../components/Footer';

import './index.css';

export default function Home(props) {

  const [navbar, setNavbar] = React.useState('transparent');

  const pageRef = React.useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    scrollTo(location.hash.slice(1))
  }, [location]);

  const scrollTo = e => {
    console.log('scroll: ', e)
    let el;
    if ( !e ) el = document.getElementById('header')
    else el = document.getElementById(e);
    el.scrollIntoView({ behavior: 'smooth' });
    // .scrollTo(e.target.getAttribute('name'), {
    //   duration: 600,
    //   delay: 200,
    //   smooth: 'easeInOutQuart'
    // })
    
  }

  const handleScroll = e => {
    console.log('scrolling');
    if ( !pageRef.current ) return;
    let pos = pageRef.current.getBoundingClientRect().top;
    console.log('scrolling: ', pos);

    if ( pos > -200 ) setNavbar('transparent')
    else setNavbar('');
  }

  const routeChange = e => { 
    console.log(e.target.value);
    navigate(e.target.value);
  };

  return <div className='home' onScroll={handleScroll}>
    <div ref={pageRef} className='home-content'>
      <Navbar 
        navbar={navbar}
        onClick={scrollTo} 
        handleContact={routeChange} 
      />
      <Header handleContact={routeChange}/>
      <HowTo handleContact={routeChange}/>
      <About handleContact={routeChange}/>
      <Tools handleContact={routeChange}/>
      <Footer/>
    </div>
  </div>
};
