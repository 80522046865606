import React from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import * as mutations from './graphql/mutations';

// import MaskedInput from 'react-text-mask'
import countryCodes from './data/international_phone_codes'

import './Authentication.css'

// Guest user

// Country codes
var fCountryCodes = [];
countryCodes.map(x => {
  if ( x.code.length < 5 ) {
    fCountryCodes.push(x)
  }  
});

fCountryCodes = fCountryCodes.reduce((fCountryCodes, current) => {
  const x = fCountryCodes.find(item => item.code === current.code);
  if (!x) {
    return fCountryCodes.concat([current]);
  } else {
    return fCountryCodes;
  }
}, []);

fCountryCodes.sort((a, b) => (a.code.length > b.code.length) ? 1 : (a.code.length === b.code.length) ? ((a.code > b.code) ? 1 : -1) : -1 );

function SignIn(props) {

  const [values, setValues] = React.useState({
    email: '', password: ''
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSignIn = () => {
    Auth.signIn(values.email, values.password)
      .then(user => console.log('user: ', user))
      .catch(error => console.log(error))
  }

  return <div className='auth-form-container'>
    <h1>¡Bienvenido!</h1>
    
    <div className="auth-form">

      <div className="auth-form-input-container">
        <label 
          htmlFor="email"
          className="auth-form-label"
        >Correo electrónico *</label>
        <input
          className="auth-form-input"
          key="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          type="text"
          placeholder="Escribe tu correo electrónico"
        />
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="password"
          className="auth-form-label"
        >Password</label>
        <input
          className="auth-form-input"
          key="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          type="password"
          placeholder="******************"
        />
        <p className="auth-form-forgot-password">
          ¿Olvidaste tu contraseña?{" "}
          <a onClick={() => props.onChange('forgotPassword')}>
            Restablecer la contraseña
          </a>
        </p>
      </div>

      <div className="auth-form-buttons-container">
        <p>¿No tienes cuenta?{" "}
          <a onClick={() => props.onChange('notSignedIn')}>
            Crear cuenta
          </a>
        </p>
        <button 
          onClick={handleSignIn}
          className='auth-form-button' 
        >
          INICIAR SESIÓN
        </button>
      </div>
      
    </div>
  </div>
}

function SignUp(props) {

  const [done, setDone] = React.useState(false);
  const [confirm, setConfirm] = React.useState({ show: false, green: false });
  const [values, setValues] = React.useState({
    name: '', 
    family_name: '', 
    email: '', 
    country_code: '+52', 
    phone_number: '', 
    password: '',
    confirm_password: '',
    company: '',
    code: ''
  });

  const handleChange = async (e) => {
    if ( e.target.name === 'password' ) {
      if ( e.target.value === values.confirm_password ) {
        setConfirm({ ...confirm, green: true })
      } else {
        setConfirm({ ...confirm, green: false })
      }
    }

    if ( e.target.name === 'confirm_password' ) {
      if ( e.target.value.length > 0 ) {
        if ( e.target.value === values.password ) {
          setConfirm({ show: true, green: true })
        } else {
          setConfirm({ show: true, green: false })
        }
      } else {
        setConfirm({ show: false, green: false })
      }
    }

    await setValues({ ...values, [e.target.name]: e.target.value })
    handleRequieredInputs();
  }

  const handleRequieredInputs = () => {
    if ( 
      (
        values.name.length &&
        values.family_name.length &&
        values.email.length &&
        values.phone_number.length &&
        values.password.length > 0
      ) && ( values.password === values.confirm_password )) {
      setDone(true);
    }
  }

  const getRandomCode = (min, max) => {
    var code = Math.floor(Math.random() * (max - min) + min);
    code = code.toString();
    setValues({ ...values, code: code });
    return code;
  }

  const handleSignUp = async () => {
    
    const { email, phone_number, country_code, password, confirm_password, name, family_name, company } = values;
    var phoneNumber = country_code + phone_number.replace(/[_() -]/g,'');

    // Phone verification
    var code = getRandomCode(100001, 999999);
    var entry = {
      id: code,
      phone_number: phoneNumber
    };
    
    // API.graphql({
    //   query: mutations.fidesaWebVerifyPhone, 
    //   variables: { input: entry },
    //   authMode: 'AWS_IAM'
    // })
    //   .then(response => JSON.parse(response.data.fidesaWebVerifyPhone))
    //   .then(responseData => console.log(responseData))
    //   .catch(err => console.log('Error sending message', err));

    // Email verification and attributes
    var today = new Date().toISOString();
    var day0 = new Date(today);
    var day1 = new Date(today);
    day1.setDate(day1.getDate() + 30);

    // Create Stripe Customer
    var entry = {
      process: 'createCustomer',
      body: JSON.stringify({
        email: email
      }),
    };
    
    // var req = await API.graphql({
    //   query: mutations.fidesaWebStripe, 
    //   variables: { input: entry },
    //   authMode: 'AWS_IAM'
    // })
    // console.log(req);
    // var customerId = JSON.parse(req.data.fidesaWebStripePayments);
    // console.log(customerId);    

    // Sign up user in Cognito
    // Auth.signUp({
    //   username: email,
    //   password,
    //   attributes: {
    //     name: name,
    //     family_name: family_name,
    //     email: email,
    //     phone_number: phoneNumber,
    //     'custom:country_code': country_code,
    //     'custom:company': company,
    //     'custom:register_date': day0.toISOString(),
    //     'custom:plan_term': 'monthly',
    //     'custom:plan': 'free',
    //     'custom:plan_property_limit': '3',
    //     'custom:plan_promoted_limit': '0',
    //     'custom:price': '0',
    //     'custom:plan_start_date': day0.toISOString(),
    //     'custom:plan_end_date': day1.toISOString(),
    //     'custom:property_ids': null,
    //   }
    // })
    //   .then(user => {
    //     console.log(user);
    //     props.onSignUp({
    //       email: email,
    //       password: password,
    //       country_code: country_code,
    //       phone_number: phone_number.replace(/[_() -]/g,''),
    //       code: code
    //     });
    //   })
    //   .catch(error => console.log('error signing up:', error));

  }

  return <div className='auth-form-container'>
    <h1>¡Regístrate!</h1>
    <p id='allast'>Campos con <span>*</span> son requeridos</p>
    
    <div className="auth-form">

      <div className='auth-form-two' >
        <div>
          <label 
            htmlFor="name"
            className="auth-form-label"
          >Nombre *</label>
          <input
            className="auth-form-input"
            name="name"
            value={values.name}
            onChange={handleChange}
            type="text"
            placeholder="Escribe tu nombre"
          />
        </div>
        <div>
          <label 
            htmlFor="username"
            className="auth-form-label"
          >Apellido *</label>
          <input
            className="auth-form-input"
            name="family_name"
            value={values.family_name}
            onChange={handleChange}
            type="text"
            placeholder="Escribe tu apellido"
          />
        </div>
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="email"
          className="auth-form-label"
        >Correo electrónico *</label>
        <input
          className="auth-form-input"
          name="email"
          value={values.email}
          onChange={handleChange}
          type="text"
          placeholder="Escribe tu correo electrónico"
        />
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="phone_number"
          className="auth-form-label"
        >Teléfono *</label>
        <div id="auth-form-phonenumber-container">
          <select 
            name="country_code" 
            value={values.country_code === '' ? '+52' : values.country_code} 
            aria-invalid="false"
            onChange={handleChange}
          >
            {fCountryCodes.map((x, i) => <option 
              key={`upload-contact-phonecode-${i}`} 
              value={x.code}
            >{x.code}
            </option>)}
          </select>
          
        </div>
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="password"
          className="auth-form-label"
        >Constraseña *</label>
        <input
          className="auth-form-input"
          name="password"
          value={values.password}
          onChange={handleChange}
          type="password"
          placeholder="******************"
        />
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="password"
          className="auth-form-label"
        >Confirma tu constraseña *</label>
        <input
          style={{ border: confirm.show ? ( confirm.green ? '2px solid green' : '2px solid red' ) : 'none' }}
          className="auth-form-input"
          name="confirm_password"
          value={values.confirm_password}
          onChange={handleChange}
          type="password"
          placeholder="******************"
        />
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="email"
          className="auth-form-label"
        >Compañía</label>
        <input
          className="auth-form-input"
          name="company"
          value={values.company}
          onChange={handleChange}
          type="text"
          placeholder="Escribe el nombre de tu compañía"
        />
      </div>

      <div className="auth-form-buttons-container">
        <p>¿Ya tienes cuenta?{" "}
          <a onClick={() => props.onChange('signedOut')}>
            Iniciar sesión
          </a>
        </p>
        <button 
          className={done ? 'auth-form-button' : 'auth-form-button-disabled' } 
          onClick={handleSignUp} 
        >
          REGISTRARME
        </button>
      </div>
      
    </div>
  </div>
}

function ConfirmSignUp(props) {

  const [codes, setCodes] = React.useState({
    email: '', phone_number: ''
  });

  let aIndex = props.values.email.indexOf("@");
  let last = props.values.email.length;
  let email = props.values.email.slice(0,3) + '*****' + props.values.email.slice(aIndex,last);;
  let phoneNumber = props.values.country_code + 
    '******' + 
    props.values.phone_number.slice(6, props.values.phone_number.length);

  console.log(props.values, phoneNumber);

  const handleChange = (e) => {
    setCodes({ ...codes, [e.target.name]: e.target.value });
  }

  const handleConfirmSignUp = () => {
    if ( codes.email.length > 0 && codes.phone_number.length > 0 ) {
      if ( codes.phone_number.toString() === props.values.code ) {
        Auth.confirmSignUp(props.values.email, codes.email)
          .then(data => {
            console.log(data);
            if ( data === 'SUCCESS' ) {
              Auth.signIn(props.values.email, props.values.password)
                .then(user => console.log(user))
                .catch(error => console.log(error))
            }
          })
          .catch(error => console.log(error))
      } else {
        console.log('Error: phone number verification code does not match') 
      }
    } else {
      console.log('Error: phone number verification code does not match')      
    }
  }

  const handleResendSignUp = () => {
    const { email } = this.state;

    Auth.resendSignUp(email)
      .then(data => console.log('code resent successfully'))
      .catch(error => console.log('error resending code: ', error))
  }

  return <div className='auth-form-container'>
    <h1>Confirma tu códigos</h1>
    <p>Los códigos pueden durar un <span>par</span> de minutos en llegar</p>
    
    <div className="auth-form">

      <div className="auth-form-input-container">
        <label 
          htmlFor="email"
          className="auth-form-label"
        >{email}</label>
        <div id='auth-form-verify-code'>
          <input
            className="auth-form-input"
            name='email'
            values={codes.email}
            onChange={handleChange}
            type="numeric"
            placeholder='Escribe el código de verificación'
          />
          <button onClick={props.handleResendSignUp}>
            Reenviar código
          </button>
        </div>
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="newCode"
          className="auth-form-label"
        >{phoneNumber}</label>
        <div id='auth-form-verify-code'>
          <input
            className="auth-form-input"
            name='phone_number'
            values={codes.phone_number}
            onChange={handleChange}
            type="numeric"
            placeholder='Escribe el código de verificación'
          />
          <button onClick={props.handleResendSignUp}>
            Reenviar código
          </button>
        </div>
      </div>

      <div className="auth-form-buttons-container">
        <a onClick={() => props.onChange('signedOut')}>
          Volver a inicio de sesión
        </a>
        <button 
          onClick={handleConfirmSignUp}
          className='auth-form-button'
        >
          CONFIRMAR
        </button>
      </div>
      
    </div>
  </div>
}

function ForgotPassword() {
  return <div className='auth-forgot-password-container'>
    Forgor password
  </div>
}

class AuthComponent extends React.Component {

  constructor(props) {
    // authState -> notSignedIn,signedIn,signedOut,confirmSignUp,forgotPassword
    super(props);
    this.state = {
      authState: 'signedOut',
      values: {}
    }
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount() {
  //   if ( this.props.isLoggedIn ) {
  //     this.setState({ authState: '' })
  //   }
  // }

  handleChange = (e) => {
    this.setState({ authState: e });
  }

  handleSignUp = (values) => {
    this.setState({ 
      authState: 'confirmSignUp',
      values: values 
    });
  }

  render() {

    const AuthProcess = () => {

      const { authState, email } = this.state;

      if ( authState === 'signedOut' ) {
        return <SignIn onChange={this.handleChange} />
      } else if ( authState === 'notSignedIn' ) {
        return <SignUp 
          onChange={this.handleChange}
          onSignUp={this.handleSignUp} 
        />
      } else if ( authState === 'confirmSignUp' ) {
        return <ConfirmSignUp 
          onChange={this.handleChange}
          values={this.state.values}
          handleConfirmSignUp={this.handleConfirmSignUp} 
          handleResendSignUp={this.handleResendSignUp} 
        />
      } else if ( authState === 'forgotPassword' ) {
        return <ForgotPassword onChange={this.handleChange} />
      }

    }

    return (
      <div className="auth-container">

        <video id="introVideo" autoPlay muted loop>
          <source src={process.env.PUBLIC_URL + "/videos/video-3.mp4"} type="video/mp4"/>
        </video> 

        <div className='auth-logo-container'>
          <img src={process.env.PUBLIC_URL + "/images/FidesaLogoCompuestoBlanco.png"} alt='logo' />
        </div>

        <AuthProcess />

      </div>
    );
  }
}

export default AuthComponent;

// @auth(
//   rules: [{ 
//     allow: public, 
//     provider: iam 
//   }]
// )