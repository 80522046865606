import React, { useState } from 'react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Dropdown from './components/Dropdown';
import DropdownCurrency from './components/DropdownCurrency';
import DropdownSearchable from './components/DropdownSearchable';

import { useHistory, useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Lottie from "lottie-react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from './graphql/mutations';

import lottie from './lotties/rocket4.json';
import industries from './data/industries.json';
import sectionsJSON from './data/sections.json';
// import toolsJSON from './data/tools.json';
import countryCodesJSON from './data/international_phone_codes.json';
import statesJSON from './data/states.json';

import './Contact.css';

const naics = industries.naics.sort((a, b) => (a > b) ? 1 : -1);
const sections = sectionsJSON.data.sort((a, b) => (a.id > b.id) ? 1 : -1);
const technologies = sections.map(x => x.name);
var tools = [];
sections.map(x => {
  x.tools.map(y => {
    if ( !tools.includes(y.name) ) {
      tools.push(y.name);
    }
  })
});

tools = tools.sort((a, b) => (a > b) ? 1 : -1);

const states = statesJSON.data;
const countries = states.map(s => s.name).sort((a, b) => (a > b) ? 1 : -1);
const times = ['1 day', '3 days', '1 week', '3 weeks', '1 month', '3 months'];
const currencies = ['MXN','USD','EUR','GBP','BTC','ETH','ADA'];
var countryCodes = countryCodesJSON
  .filter(x => x.code.length < 5 )
  .sort((a, b) => (a.code.length > b.code.length) ? 1 : (a.code.length === b.code.length) ? ((a.code > b.code) ? 1 : -1) : -1 )
  .map(x => x.code);

countryCodes = countryCodes.filter((v,i) => countryCodes.indexOf(v) === i);

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function Contact() {
  const search = useLocation().search;
  const technology = new URLSearchParams(search).get('technology');
  const tool = new URLSearchParams(search).get('tool');
  
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({})

	const handleSubmit = contact => {
  	var sendValues = { ...values };
    Object.keys(contact).forEach(x => {
      sendValues[x] = contact[x]
    });
    // console.log(sendValues);

    API.graphql({ 
      query: mutations.sandPalaceSendMail, 
      variables: { input: sendValues }
    })
      .then(response => console.log(response.data.sandPalaceSendMail))
      .catch(error => console.log(error));
      
    alert('Your email was sent successfully!');
    window.location.reload(false);
  }

  const Project = () => {
    const [projTools, setProjTools] = useState([]);
    const [project, setProject] = useState({
      project: '',
      description: '',
      industry: '',
      technology: technology || '', //
      tool: tool || '', //
      section: '',
      time: '',
      remote: true, //
      currency: 'USD', //
      budget: 1000 //
    })

    React.useEffect(() => {
      if ( project.technology !== '' ) {
          var newTools = sections.filter(x => x.name === project.technology)[0].tools;
          setProjTools(newTools.map(x => x.name));
      }
    }, [])

    const handleChange = async e => {
      if ( e.target.name === 'description' && e.target.value.length > 140 ) {
        return false;
      } else {
        setProject({...project, [e.target.name]: e.target.value });
      }
    }

    const NextButton = () => {
      if ( 
        project.project === '' ||
        project.description === '' ||
        project.industry === '' ||
        project.technology === '' ||
        [0, undefined].includes(project.budget)
      ) {
        return <div className='contact-form-submit'>
          <div>Next</div>
        </div>
      } else {
        return <div className='contact-form-submit'>
          <button 
            onClick={() => {
              setValues(project);
              setStep(2);
            }}
          >
            Next
          </button>
        </div> 
      }
    }

    return <div className='contact-form'>
      <div className='contact-project-title'>
        Submit your project
      </div>

      <div className='contact-single-line'>
        <label>Project's name *</label>
        <input 
          className='contact-form-input'
          type="text" name='project' style={{width: '80%'}}
          value={project.project} onChange={handleChange} 
        />
      </div>

      <div className='contact-single-line'>
        <label>Description in less than 140 characters *</label>
        <textarea 
          className='contact-form-input'
          name='description'
          rows="3"
          cols="47"
          onChange={handleChange}
          value={project.description}
        />
      </div>

      <div>
        <label>Industry *</label>
        <DropdownSearchable 
          name='industry'
          options={naics} 
          value={project.industry}
          posY={'bottom'}
          posX={'left'}
          onChange={async e => setProject({...project, industry: e })}
        />
      </div>
      
      <div>
        <label>Technology *</label>
        <DropdownSearchable 
          name='technology'
          options={technologies}
          value={project.technology}
          posY={'bottom'}
          posX={'right'}
          onChange={async e => {
            setProject({...project, technology: e });
            console.log(e, technologies.includes(e));
            if ( technologies.includes(e) ) {
              var newTools = sections.filter(x => x.name === e)[0].tools;
              setProjTools(newTools.map(x => x.name));
            }
          }}
        />
      </div>

      <div>
        <label>Tool</label>
        <DropdownSearchable 
          name='tool'
          options={projTools}
          value={project.tool}
          posY={'top'}
          posX={'left'}
          onChange={async e => {
            var newTech = sections
              .filter(x => x.name === project.technology)[0];
            
            var newTool = newTech.tools
              .filter(x => x.name === e)[0];

            setProject({...project, 
              tool: e,
              section: newTech.id+'-'+newTool.id
            })
          }}
        />
      </div>

      <div>
        <label>Estimated time</label>
        <Dropdown 
          name='time'
          options={times} 
          value={project.time}
          posY={'top'}
          posX={'right'}
          onChange={async e => setProject({...project, time: e })}
        />
      </div>

      <div>
        <label>Can you work remotely? *</label>
        <div className='contact-form-remote'>
          <label>Yes
            <input 
              type="checkbox" 
              style={{ marginLeft: '10px' }} 
              name='remote'
              checked={ project.remote === true ? true : false }
              value={project.remote} 
              onChange={() => setProject({ ...project, remote: true })} 
            />
          </label>
          <label>No
            <input 
              type="checkbox"
              style={{marginLeft: '10px'}} 
              name='remote'
              checked={ project.remote === false ? true : false }
              value={project.remote} 
              onChange={() => setProject({ ...project, remote: false })} 
            />
          </label>
        </div>
      </div>
      
      <div>
        <label>Budget *</label>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '60px auto',
          width: '100%',
          height: '36px',
          marginTop: '8px',
        }}>
          <DropdownCurrency 
            name='currency'
            options={currencies} 
            value={project.currency}
            posY={'top'}
            posX={'left'}
            onChange={async e => setProject({...project, currency: e })}
          />

          <div className="contact-budget-wrapper">
            <NumericFormat 
              inputMode="numeric"
              value={project.budget}
              name='budget' 
              style={{ textAlign: 'center' }}
              className="contact-budget" 
              displayType={'input'} 
              thousandSeparator={true} 
              prefix={'$'} 
              onValueChange={async budgets => {
                console.log(budgets.floatValue);
                setProject({ ...project, budget: budgets.floatValue })
              }}
            />
          </div>
        </div>
      </div>

      <div className='contact-form-note'>
        <p>Fields with <span>*</span> are requiered</p>
      </div>
      
      <NextButton />
    </div>
  }

  const ContactInfo = () => {
    var country = 'United States';
    const [cities, setCities] = useState(states
      .filter(s => s.name === country)[0]
      .states
      .map(s => s.name)
    );

    const [contact, setContact] = useState({
      name: '',
      lastName: '',
      countryCode: '+1', //
      phoneNumber: '',
      email: '',
      country: country, //
      city: '',
      company: '',
      position: '',
      weapon: '',
    });
    
    const handleContact = async e => {
      setContact({ ...contact, [e.target.name]: e.target.value });
    }

    const SubmitButton = () => {
      if ( 
        contact.name === '' ||
        contact.lastName === '' ||
        contact.email === '' ||
        contact.country === '' ||
        contact.city === ''
      ) {
        return <div className='contact-form-submit'>
          <div>Submit</div>
        </div>
      } else {
        return <div className='contact-form-submit'>
          <button onClick={() => handleSubmit(contact)}>
            Submit
          </button>
        </div>
      }
    }

    return <div className='contact-form'>
      <div className='contact-title'>
        <span>Contact information</span>
        <button onClick={() => setStep(1)}>
          {`<< Back`}
        </button>
      </div>

      <div>
        <label>Name *</label>
        <input 
          className='contact-form-input'
          type="text" 
          name='name' 
          value={contact.name} 
          onChange={handleContact}
        />
      </div>

      <div>
        <label>Last name *</label>
        <input 
          className='contact-form-input'
          type="text" 
          name='lastName' 
          value={contact.lastName} 
          onChange={handleContact}
        />
      </div>

      <div className='contact-single-line'>
        <label>E-mail *</label>
        <input 
          className='contact-form-input'
          type="numeric" 
          name='email' 
          value={contact.email} 
          onChange={handleContact}
        />
      </div>

      <div className='contact-single-line'>
        <label>Phone number</label>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '100px auto',
          width: '100%',
          height: '36px',
          marginTop: '8px',
        }}>
          <DropdownCurrency 
            name='countryCode'
            options={countryCodes}
            value={contact.countryCode}
            posY={'top'}
            posX={'left'}
            onChange={e => setContact({ ...contact, countryCode: e })}
          />

          <div className="contact-budget-wrapper">
            <NumericFormat 
              inputMode="numeric"
              value={contact.phoneNumber}
              name='phoneNumber' 
              className="contact-budget" 
              style={{ textAlign: 'left' }}
              displayType='input'
              type='tel'
              format='(###) ### ####'
              onValueChange={async values => {
                setContact({ ...contact, phoneNumber: values.floatValue })
              }}
            />
          </div>
        </div>
      </div>
      
      <div className='contact-single-line'>
        <label>Company</label>
        <input 
          className='contact-form-input'
          type="numeric" 
          name='company' 
          value={contact.company} 
          onChange={handleContact}
        />
      </div>

      <div>
        <label>Position</label>
        <input 
          className='contact-form-input'
          type='text'
          name='position' 
          value={contact.position} 
          onChange={handleContact}
        />
      </div>

      <div>
        <label>Daily tool</label>
        <DropdownSearchable 
          name='weapon'
          options={tools}
          value={contact.weapon}
          posY={'top'}
          posX={'left'}
          onChange={e => setContact({ ...contact, weapon: e })}
        />
      </div>
      
      <div>
        <label>Country *</label>
        <DropdownSearchable 
          name='country'
          options={countries}
          value={contact.country}
          posY={'top'}
          posX={'left'}
          onChange={e => {
            if ( countries.includes(e) ) {
              console.log('match: ', e);
              var newCities = states
                .filter(s => s.name === e)[0]
                .states
                .map(s => s.name);

              console.log('newCities: ', newCities);
              setCities(newCities);
            } else {
              console.log('not match: ', e);
              setCities([]);
            }
            setContact({ ...contact, country: e })
          }}
        />
      </div>

      <div>
        <label>City *</label>
        <DropdownSearchable 
          name='city'
          options={cities} 
          value={contact.city}
          posY={'top'}
          posX={'right'}
          onChange={e => setContact({ ...contact, city: e })}
        />
      </div>

      <div className='contact-form-note'>
        <p>Fields with <span>*</span> are requiered</p>
      </div>
      
      <SubmitButton />

    </div>
  }

  return <div className='contact-container'>
    <Navbar />
    
    <div className='contact-lottie-container'>
	    <Lottie 
        animationData={lottie} 
        loop={true} 
      />;
	  </div>

    { step === 1 ? 
        <Project /> : 
        <ContactInfo /> 
    }

    <Footer/>
  </div>
}
// <input type="submit" value="Submit" />

export default Contact;