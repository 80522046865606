import React from 'react';

import { API, Amplify, Auth, Hub } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';

import AuthComponent from './Authentication';
import Home from './Landing';
import Console from './Console';
import Contact from './Contact';
import NotFound from './NotFound';

import './App.css';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    // await Auth.signIn('hola@fidesa.mx', 'Vision2021!')
    //   .then(user => console.log(user))
    //   .catch(error => console.log(error))

    // await Auth.currentCredentials()
    //   .then(d => console.log('creds: ', d))
    //   .catch(e => console.log('error: ', e))

    Auth.currentAuthenticatedUser()
      .then(user => setIsLoggedIn(true))
      .catch(error => {
        console.log(error)
        setIsLoggedIn(false)
      })

    async function signIn() {
        try {
            const user = await Auth.signIn(
              'hello@sandpalace.io', 
              'SandPalace*'
            );
        } catch (error) {
            console.log('error signing in', error);
        }
    }
    // signIn();

  }, [])

  Hub.listen('auth', (data) => {
    const event = data.payload.event;
    if (event === "signIn") {
      console.log('user signed in...');
      setIsLoggedIn(true);
    } else if (event === "signOut") {
      console.log('user signed out...');
      setIsLoggedIn(false);
    }
  });

  const ProtectedRoutes = ({ children }) => {
    return isLoggedIn ? children : <AuthComponent />;
  };

  return <BrowserRouter>      
    <Routes>
      <Route path='/console' element={<Console />} />
      <Route path='/contact/:technology/:tool' element={<Contact />} />
      <Route index element={<Home />} />

      <Route path='404' element={<NotFound />} />    
    </Routes>
  </BrowserRouter>
}

export default App;
// export default withAuthenticator(App);
