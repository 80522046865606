import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Menu from './Menu';
import Footer from '../components/Footer';

import './index.css';

function Console() {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => { setIsLoading(false) }, 3200);

  return <div className='console'>
    <Navbar/>
    <Menu/>
  </div>
}


export default Console;