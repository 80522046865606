/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sandPalaceSendMail = /* GraphQL */ `
  mutation SandPalaceSendMail($input: mailInput) {
    sandPalaceSendMail(input: $input)
  }
`;
export const createSandPalaceDB = /* GraphQL */ `
  mutation CreateSandPalaceDB(
    $input: CreateSandPalaceDBInput!
    $condition: ModelSandPalaceDBConditionInput
  ) {
    createSandPalaceDB(input: $input, condition: $condition) {
      pk
      sk
      value
      name
      active
      public
      description
      createdAt
      updatedAt
      status
      laborCosts
      materialCosts
      overallCosts
      subject
      startDate
      dueDate
      owner
    }
  }
`;
export const updateSandPalaceDB = /* GraphQL */ `
  mutation UpdateSandPalaceDB(
    $input: UpdateSandPalaceDBInput!
    $condition: ModelSandPalaceDBConditionInput
  ) {
    updateSandPalaceDB(input: $input, condition: $condition) {
      pk
      sk
      value
      name
      active
      public
      description
      createdAt
      updatedAt
      status
      laborCosts
      materialCosts
      overallCosts
      subject
      startDate
      dueDate
      owner
    }
  }
`;
export const deleteSandPalaceDB = /* GraphQL */ `
  mutation DeleteSandPalaceDB(
    $input: DeleteSandPalaceDBInput!
    $condition: ModelSandPalaceDBConditionInput
  ) {
    deleteSandPalaceDB(input: $input, condition: $condition) {
      pk
      sk
      value
      name
      active
      public
      description
      createdAt
      updatedAt
      status
      laborCosts
      materialCosts
      overallCosts
      subject
      startDate
      dueDate
      owner
    }
  }
`;
