import React from 'react';


const NotFound = () => {
    return (
        <div>
            <h3>404</h3>
            <small>NotFound</small>
        </div>
    );
};

export default NotFound;