import React, { useState, useEffect, useRef } from 'react';

import './DropdownSearchable.css';

function cleanString(str) {
  if ( str ) {
    return str
      .replace(/[\u0300-\u036f]/g, "")
      .normalize("NFD")
      .toLowerCase()
      .trim()
  }
}

const DropdownSearchable = props => {
  const [cursor, setCursor] = useState(0);
  const [arrows, setArrows] = useState({
  	up: false, down: true
  });
  const [wrapperPos, setWrapperPos] = useState({});
  const [menuPos, setMenuPos] = useState({});
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  const [mobile, setMobile] = useState(false);

  React.useEffect(() => {
      function changeBackground() {
		    if (window.innerWidth <= 641) {
		      setMobile(true);
		    } else {
		      setMobile(false);
		    }
		  };
      
      window.addEventListener('resize', changeBackground);
      return () => {
      	window.removeEventListener('resize', changeBackground);
      };
  }, []);

	function changeBackground() {
    if (window.innerWidth <= 641) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  function filterOptions(x) {
  	if ( 
  		props.options
  			.filter(option => cleanString(option) === cleanString(x))
  			.length > 0 
		) {
			return props.options
		} else if ( 
			props.options
				.filter(option => cleanString(option)
	  			.includes(cleanString(x))
  			).length > 0
		) {
  		return props.options.filter(option => cleanString(option)
				.includes(
					cleanString(x)
				))
		}
  	return props.options
  }

  const handleChange = e => {
  	setCursor(0);
		setShow(true);
	  		
		if ( filterOptions(props.value).length > 5 ) {
			setArrows({ up: false, down: false });
		}

		props.onChange(e.target.value);
  }

  const handleKey = e => {
  	// Positions
  	var menuName = `contact-menu-${props.name}`;
  	var currentOptionName = `option-${props.name}-${cursor}`;
  	
  	var menuEl = document.getElementById(menuName);
  	var currentEl = document.getElementById(currentOptionName);
  	
  	if ( menuEl && currentEl ) {
  		var menuElPos = menuEl.getBoundingClientRect();
  		var currentElPos = currentEl.getBoundingClientRect();

  		var menu = (menuElPos.top + menuElPos.bottom) / 2;
  		var current = (currentElPos.top + currentElPos.bottom) / 2;

  		// Options
	    if (e.key === 'Escape') {
	      setShow(false);
	    } else if ( e.key === 'ArrowUp' ) {
	      if ( show && cursor > 0 ) {
	        setCursor(cursor - 1)
	      }
	    } else if ( e.key === 'ArrowDown' ) {
	      if ( show && cursor < filterOptions(props.value).length-1 ) 
	      {
	        setCursor(cursor + 1);
	      } 
	      if ( !show && focus ) {
	      	setShow(true);
	      	setCursor(0);
	      }
	    } else if ( e.key === 'Enter' ) {
	      if ( show ) {
	        setShow(false);
	        props.onChange(filterOptions(props.value)[cursor]);
	      }
	    }
  	}
  }

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShow(false);
        }
      }
      
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, cursor, show, props]);
  }

  function usePosition(ref) {
    useEffect(() => {
      function handleScroll(event) {
      	var menuName = `contact-menu-${props.name}`
      	var firstName = `option-${props.name}-0`
      	var lastName = `option-${props.name}-${filterOptions(props.value).length - 1}`
      	
      	var menuEl = document.getElementById(menuName);
      	var firstEl = document.getElementById(firstName);
      	var lastEl = document.getElementById(lastName);

        if ( ref.current && menuEl && firstEl && lastEl ) {
        	var menuPos = menuEl.getBoundingClientRect();
        	var firstPos = firstEl.getBoundingClientRect();
        	var lastPos = lastEl.getBoundingClientRect();
        	var offset = 10;

          if ( firstPos.top + offset < menuPos.top && lastPos.bottom - offset > menuPos.bottom ) {
          	setArrows({ up: true, down: true });
          } else if ( firstPos.top + offset < menuPos.top && lastPos.bottom - offset <= menuPos.bottom ) {
          	setArrows({ up: true, down: false });
          } else if ( firstPos.top + offset >= menuPos.top && lastPos.bottom - offset > menuPos.bottom ) {
          	setArrows({ up: false, down: true });
          } else {
          	setArrows({ up: false, down: false });
          }
        }
      }
      
      document.addEventListener("wheel", handleScroll);
      return () => {
      	document.removeEventListener("wheel", handleScroll);
      };
    }, [ref, props]);
  }

	const inputRef = useRef(null);
  useOutside(inputRef);

  const menuRef = useRef(null);
  usePosition(menuRef);

	return <div 
		ref={inputRef} 
		className='contact-search'
		onKeyDown={handleKey}
	>
		<input 
			className='contact-form-input'
			style={{width: props.width}}
    	type="text" 
    	autoComplete='off'
    	name={props.name} 
    	value={props.value} 
    	onChange={handleChange} 
    	onClick={() => setShow(!show)}
    	onFocus={() => setFocus(true)}
    	onBlur={() => setFocus(false)}
  	/>

  	<div 
			id={`contact-menu-${props.name}`}
			style={{
				left: props.posX === 'left' ? '0px' : '',
				right: props.posX === 'right' ? '0px' : '',
				top: props.posY === 'bottom' ? (mobile ? '60px' : '68px') : '',
				bottom: props.posY === 'top' ? (mobile ? '46px' : '48px') : '',
				height: filterOptions(props.value).length < 5 ? 
					(mobile ? 
						`${filterOptions(props.value).length * 36}px` : 
						`${filterOptions(props.value).length * 40}px`) : 
						(mobile ? '144px' : '200px'),
				display: show ? 'block' : 'none'
			}}
			className='contact-menu-search'
		>	
			<div 
				className='contact-menu-arrow-up-wrapper'
				style={{ display: arrows.up ? 'block' : 'none' }}
			>
				<span className='contact-menu-arrow-up'>
		      <i className='fas fa-caret-up' />
				</span> 
			</div>

			<div 
				className='contact-menu-arrow-down-wrapper'
				style={{ display: arrows.down ? 'block' : 'none' }}
			>
				<span className='contact-menu-arrow-down'>
	      	<i className='fas fa-caret-down' />
				</span> 
			</div> 
			
			<div 
				id={`contact-menu-search-ul-${props.name}`}
				className='contact-menu-ul'
				ref={menuRef} 	
			>
				{filterOptions(props.value).map((option, i) => {
					return <button 
						index={i}
						id={`option-${props.name}-${i}`}
						key={`option-${props.name}-${i}`}
						className='contact-search-option'
						style={{
							pointerEvents: props.value === option ? 'none' : '',
							cursor: props.value === option ? 'default' : 'pointer',
							color: props.value === option ? '#ffdc32' : '#fff',
							backgroundColor: i === cursor ? 'rgb(10,10,10,0.2)' : 'transparent',
						}}
						name={props.name}
						value={option}
						onClick={e => {
							setShow(false);
							props.onChange(e.target.value);
						}}
						onMouseEnter={e => setCursor(
			      	parseInt(e.target.getAttribute('index')) 
			      )}
						onMouseLeave={e => setCursor(0)}
					>{option}</button>
				})}	
			</div>

		</div>
		
	</div>
}

export default DropdownSearchable;