import React from 'react';

import Lottie from 'lottie-react';
import lottie_users from '../lotties/users-and-vr-loop';
import lottie_green_city from '../lotties/green_world';

import { Link } from 'react-router-dom';

import './HowTo.css';

const steps = [
  {
    icon: 'fas fa-clipboard-check',
    desc: 'Fill project requierement'
  }, {
    icon: 'fas fa-user',
    desc: 'Talk to our Project Managers'
  }, {
    icon: 'fas fa-check',
    desc: 'Choose your favorite'
  }, {
    icon: 'fas fa-arrow-right',
    desc: 'Start now!'
  }
]

function HowTo(props) {

  const Figures = () => {
    return <div className='howto-figures'>
      <div className='howto-square0'/>
      
      <div className='howto-dots'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile4.png"} alt="yellow-dots"/>
      </div>
      <div className='howto-dots2'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile5.png"} alt="yellow-dots"/>
      </div>
      <div className='howto-dots3'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile6.png"} alt="yellow-dots"/>
      </div>
      <div className='howto-square1'/>
      <div className='howto-square2'/>
      <Link to='/contact'>
        <button className='howto-square3'>
          START
        </button>
      </Link>
      <div className='howto-square4'/>
    </div>
  };

  const Steps = () => {
    var options = [];
    steps.map((step, i) => {
      if ( i !== steps.lenght-1 ) {
        options.push(
          <div key={`howto-step-${i}`} className={ i === steps.length-1 ? 'howto-step-container active' : 'howto-step-container'}>
          <div className='howto-step'>
            <div className='howto-step-number'>
              {i+1}
            </div>
            <div className='howto-step-icon'>
              <div>
                <span>
                  <i className={step.icon} />
                </span>
              </div>
            </div>
            <div className='howto-step-desc'>
              <p>{step.desc}</p>
            </div>
          </div>
          </div>
        )
      } else {
        console.log('last')
        options.push(
          <div key={`howto-step-${i}`} className={ i === steps.length-1 ? 'howto-step-container active' : 'howto-step-container'}>
            <Link to='/contact'>
              <button className='howto-step'>
                <div className='howto-step-number'>
                  {i+1}
                </div>
                <div className='howto-step-icon'>
                  <div>
                    <span>
                      <i className={step.icon} />
                    </span>
                  </div>
                </div>
                <div className='howto-step-desc'>
                  <p>{step.desc}</p>
                </div>
              </button>
            </Link>
          </div>
        )
      }
    });

    return <div className='howto-steps'>
      {options}
    </div>
  }

  return <div id='howto' className='howto'>
    <Figures />
    
    <div className='howto-content'> 
      <div className='howto-content-title'>
        · Start your project in 4 easy steps ·
      </div>
      <Steps />
    </div>
  </div>
}

export default HowTo;
