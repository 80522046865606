import React from 'react';

import Lottie from 'lottie-react';
import lottie_users from '../lotties/users-and-vr-loop';
import lottie_green_city from '../lotties/green_world';
import lottie_smart_city from '../lotties/smart-city';
import lottie_building from '../lotties/building';
import lottie_pagina_web from '../lotties/pagina_web';
import lottie_searching from '../lotties/searching';
import lottie_desarrollo_web from '../lotties/desarrollo_web';
import lottie_robot_selecting from '../lotties/robot_selecting';
import lottie_chatbot from '../lotties/chatbot';
import lottie_dashboard from '../lotties/lottie_dashboard';

import { Link } from 'react-router-dom';

import './About.css';
// "Agile methodologies", "Automation", "BackEnd", "Big Data", "Blockchain", "Chatbots", "Cloud", "Computer vision", "Data cleaning", "Data exploration", "Data visualization", "FrontEnd Apps", "FrontEnd Web", "GIS", "IoT", "Machine Learning", "Web Scraping"
const services_en = [
  {
    name: 'Process automation',
    desc: "Integrate computational systems to automatically execute faster and more precise tasks.",
    technology: 'Automation',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_smart_city,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Data arquitecture',
    desc: 'Design and build the rules, processes, cybersecurity and access management policies that govern your data collection.',
    technology: 'Big data',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_building,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Web Scraping',
    technology: 'Web scraping',
    tool: '',
    desc: "Is the process of extracting data from websites automatically.",
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_pagina_web,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Data exploration',
    technology: 'Data exploration',
    tool: '',
    desc: 'Is the first step to any analytical project. Variable understanding, missing data and pattern insights.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_searching,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Predictive analytics',
    desc: "Is the sum of statistical methods used in data mining, forecasting and machine learning to predict future events based on historical data.",
    technology: 'Machine learning',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_robot_selecting,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Data vizualization',
    desc: 'Is the graphical representation of data. The perfect way to digest ANY kind of data and help to make the decision process easier and faster.',
    technology: 'Data visualization',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_dashboard,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'App & Web development',
    desc: 'In this modern era, digital interactions have become essential to B2B and B2C relationships. We help you all the way, from design to maintainance.',
    technology: 'FrontEnd Apps',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_desarrollo_web,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Chatbots',
    desc: 'Learn to delegate... to a ROBOT🤖. They can work 24/7 and without salary!',
    technology: 'Chatbots',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_chatbot,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'Marketing',
    desc: 'Knowing your customer is the heart of your business. Add some machine learning and automation and see what happens.',
    technology: 'Big data',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_users,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
  {
    name: 'GIS',
    desc: '80% of all data is georeferenced. Geographic Information Systems help us integrate spatial data into statistical methods.',
    technology: 'GIS',
    tool: '',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_green_city,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'about-lottie-container',
      }
    }
  },
];

const services_es = [
  {
    name: 'Automatización de procesos',
    desc: "Es el procedimiento en el que se integran sistemas de computo para ejecutar tareas repetitivas o muy precisas de la cadena de producción.",
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_smart_city,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Arquitectura de datos',
    desc: 'Es el modelo de reglas, procesos, seguridad y permisos que gobiernan una colección de datos. ¿Qué data es colectada? ¿cómo se almacena, acomoda, integra y se usa en sistemas y procesos de la organización?',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_building,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Web Scraping',
    desc: "Es la extracción de datos de páginas web. Pudiendo accesar a todo el internet a través de buscadores, software especializado o procesos automatizados por bots.",
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_pagina_web,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Exploración de datos',
    desc: 'Es el primer acercamiento a los datos. Empieza en el peso de los datos, número de registros y datos faltantes, y termina con el entendimiento de la información, relaciones o patrones entre variables o en el tiempo.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_searching,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Análisis predictivo',
    desc: "Es el conjunto de técnicas estadísticas usadas en la explotación de datos, modelación predictiva y aprendizaje de máquinas que analizan datos actuales e históricos para hacer predicciones del futuro o eventos deconocidos.",
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_robot_selecting,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Dashboards',
    desc: 'Es un panel de control que sintetiza información en una pantalla para poder digerirla más rápida y consistentemente, y así mejorar el proceso toma de decisiones.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_dashboard,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Desarrollo de aplicaciones y web',
    desc: 'En la era de la digitalización. Las plataformas digitales se volvieron esenciales para las relaciones comerciales, B2B y B2C. Te ayudamos en todo el camino, desde el diseño hasta su mantenimiento.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_desarrollo_web,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Chatbots',
    desc: 'Aprendamos a delegar... a los robots. Un ROBOT🤖 puede atender clientes 24/7 y no está en la nómina!',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_chatbot,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Perfilamiento de usuarios',
    desc: 'El conocimiento de cliente es el corazón de un negocio prospero. Con un poco de machine learning y automatización de procesos es posible fortalecer la relación y experiencia del cliente con hipersegmentación y personalización de la propuesta de valor.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_users,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
  {
    name: 'Análisis geográfico',
    desc: 'Es la rama que aplica métodos estadísticos a información georeferenciada. Puede ser la locación de un negocio, el trayecto de un viaje, la densidad de hogares, un domicilio de entrega, un checkin o hasta un click.',
    gifOptions: {
      loop: true,
      autoplay: true,
      animationData: lottie_green_city,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
        className: 'lottie',
      }
    }
  },
];

function About() {
  const [mobil, setMobil] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  const aboutEl = React.useRef();
  const lastEl = React.useRef();

  React.useEffect(() => {
    changeBackground();
  }, []);

  const changeBackground = () => {
    if (window.innerWidth <= 641) {
      setMobil(true);
    } else {
      setMobil(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  // React.useEffect(() => {
  //   function changeScroll() {
  //     var pos = aboutEl.current?.getBoundingClientRect();
  //     if ( pos.top <= 90 && pos.top >= 80 ) {
  //       setScroll(true);
  //     } else {
  //       setScroll(false);
  //     }
  //   }

  //   window.addEventListener('scroll', changeScroll);
  //   return () => {
  //     window.removeEventListener('scroll', changeScroll);
  //   }
  // }, [aboutEl]);

  const Figures = () => {
    return <div className={ true 
      ? 'about-figures' 
      : 'about-figures' }
    >
      <div className='about-dots'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile.png"} alt="yellow-dots"/>
      </div>
      <div className='about-dots2'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile2.png"} alt="yellow-dots"/>
      </div>
      <div className='about-dots3'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile1.png"} alt="yellow-dots"/>
      </div>
      <div className='about-square0'/>
      <div className='about-square1'/>
      <div className='about-square2'/>
      <div className='about-square3'/>
      <div className='about-square4'/>
      <div className='about-square5'/>
      <div className='about-square6'/>
    </div>
  }

  const Chip = props => {
    if ( props.index === 0 ) {
      if ( mobil ) {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chipMobileWO.png"} alt="chip"/>
        </div> 
      } else {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chip.svg"} alt="chip"/>
        </div>
      }
    } else if ( props.index+1 === services_en.length ) {
      if ( mobil ) {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chipMobileWLAST.png"} alt="chip2"/>
        </div>
      } else {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chip2.svg"} alt="chip2"/>
        </div>
      }
    } else {
      if ( mobil ) {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chipMobileW.png"} alt="chip2"/>
        </div>
      } else {
        return <div className='about-chip'>
          <img src={process.env.PUBLIC_URL + "/media/chip2.svg"} alt="chip2"/>
        </div>
      }
    }
  }

  return <div ref={aboutEl} id='cases' className='about'>
    <Figures />
    
    <div className={ scroll ? 'about-content active' : 'about-content' }>    
      <h3>· Use cases ·</h3>
      
      <div className='about-cases'>    
        {services_en.map((s, i) => {
          return <Link 
            key={`about-case-${i}`} 
            to={{
              pathname: '/contact',
              search: `?technology=${s.technology}`
            }}
            style={{ textDecoration: 'none' }}
          >
            <div 
              ref={i+1 === services_en.length ? lastEl : null}
              id={`about-case-${i}`} 
              className={i % 2 === 0 ? 'about-case' : 'about-case active'}
            >
              <Chip index={i} />

              <div className='about-case-content'>
                <div className='about-case-title'>{s.name}</div>
                <div className='about-case-desc'>
                  {s.desc}
                </div>
                <div className='about-lottie-container'>
                  <Lottie options={s.gifOptions}/>
                </div>
              </div>

            </div>
          </Link>
        })}
      </div>

    </div>
  </div>
}

export default About;
