import React from 'react';
import './Footer.css';

function Footer() {
  
  return (
    <div id='Footer' className='footer'>

      <div className='copyright'>
        © 2020 Copyright |&nbsp;<span>SandPalace</span>
      </div>
      
      <div className='social'>
        <a href='https://www.facebook.com/cnamty' target="_blank">
          <i className='fa fa-facebook' />
        </a>
        <a href='https://twitter.com/cnamexico' target="_blank">
          <i className='fa fa-twitter' />
        </a>
        <a href='https://www.linkedin.com/company/cnamexico' target="_blank">
          <i className='fa fa-linkedin-square' />
        </a>
      </div>

    </div>
  );
}

export default Footer;
