import React from 'react';

// import video from "../media/videoData4.mp4";
import Lottie from 'lottie-react';
import lottie1 from '../lotties/people_seating_working';

import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';

import './Header.css';

const lottieSettings = {
  loop: true,
  autoplay: true,
  animationData: lottie1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  }
}

const jobs = [
  {name: '#dataScientist', technology: 'Data exploration'},
  {name: '#dataEngineer', technology: 'Big data'},
  {name: '#dataArchitect', technology: 'Big data'},
  {name: '#webDeveloper', technology: 'FrontEnd Web'},
  {name: '#appDeveloper', technology: 'FrontEnd App'},
  {name: '#frontEndDeveloper', technology: 'FrontEnd Web'},
  {name: '#backEndDeveloper', technology: 'BackEnd'},
  {name: '#fullStackDeveloper', technology: 'Web Development'},
  {name: '#MLengineer', technology: 'Machine learning'}
]

var alpha = '1';
var colors = {
  'rgb(250,250,250)': [`rgba(0,204,190,${alpha})`,`rgba(92,15,139,${alpha})`,`rgb(0,85,184,${alpha})`],
  'rgb(255,220,50)': [`rgba(92,15,139,${alpha})`,`rgb(0,85,184,${alpha})`],
  'rgb(0,204,190)': [`rgba(92,15,139,${alpha})`],
  'rgb(92,15,139)': [`rgb(250,250,250,${alpha})`,`rgb(255,220,50,${alpha})`],
  'rgb(0,85,184)': [`rgb(250,250,250,${alpha})`,`rgb(255,220,50,${alpha})`]
};

alpha = '0.8';
var colorsA = {
  'rgb(250,250,250)': [`rgba(0,204,190,${alpha})`,`rgba(92,15,139,${alpha})`,`rgb(0,85,184,${alpha})`],
  'rgb(255,220,50)': [`rgba(92,15,139,${alpha})`,`rgb(0,85,184,${alpha})`],
  'rgb(0,204,190)': [`rgba(92,15,139,${alpha})`],
  'rgb(92,15,139)': [`rgb(250,250,250,${alpha})`,`rgb(255,220,50,${alpha})`],
  'rgb(0,85,184)': [`rgb(250,250,250,${alpha})`,`rgb(255,220,50,${alpha})`]
};

function selectRandom(x) {
  return Math.floor(Math.random() * x);
}

function selectRandomList(x) {
  return x[Math.floor(Math.random() * x.length)];
}

var values = [];
jobs.map((job, i) => {
  var left = selectRandom(45) + 5;
  
  var color = selectRandomList(Object.keys(colors));
  var randomIdx = selectRandom(Object.keys(colors[color]).length);
  var bga = colorsA[color][randomIdx];
  var bg = colors[color][randomIdx];

  var sides = ['','-'];
  var side = selectRandomList(sides);
  var angle = selectRandom(20);
  var rotation = `rotate(${side}${angle}deg)`;
  var rotation2 = `rotate(${sides.filter(x => x !== side)[0]}${angle}deg)`;

  var r = {
    left: `${left}%`,
    color: color,
    bga: bga,
    bg: bg,
    rotation: rotation,
    rotation2: rotation2,
  };
  values.push(r);
});

function Shade() {
  const [alpha, setAlpha] = React.useState(0);

  const changeAlpha = () => {
    setAlpha(
      ((window.scrollY / (window.innerHeight - 120)) ** 2).toFixed(2)
    );
  }

  window.addEventListener('scroll', changeAlpha);

  return <div 
    className='header-shade' 
    style={{opacity: alpha}}
  />
}

function Header() {

  const [video, setVideo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let componentMounted = true;

    const fetchData = async () => {      
      let newVideo = await Storage.get('video-particles.mp4', { level: 'public' });
      console.log('new video: ', newVideo);

      if ( componentMounted ) {
        setVideo(newVideo);
        setTimeout(() => setIsLoading(false), 1500);
      }
    };
    
    fetchData();
    return () => componentMounted = false;
  }, []);

  const Tags = () => {
    const [cursor, setCursor] = React.useState(null);

    var options = [];
    if ( values.length > 0 ) {
      jobs
        .sort((a, b) => (a.name > b.name) ? 1 : -1)
        .map((job, i) => {
          options.push(<div key={`header-role-${i}`} className='header-role-wrapper'>
            <Link to={{
              pathname: '/contact',
              search: `?technology=${job.technology}`
            }}>
              <button 
                className='header-role'
                style={{
                  transform: i.toString() !== cursor ? values[i].rotation : values[i].rotation2,
                  color: values[i].color,
                  backgroundColor: i.toString() !== cursor ? values[i].bga : values[i].bg,
                  fontWeight: i.toString() !== cursor ? 'regular' : 'bold',
                }}
                index={i}
                onMouseEnter={e => setCursor(e.target.getAttribute('index'))}
                onMouseLeave={e => setCursor(null)}
              >
                {job.name}
              </button>
            </Link>
          </div>)
        })
    }

    return <div className='header-roles'>
      {options}
    </div>
  };

  return <div id='header' className='header'>
    <div className="header-video">
      { video && <video autoPlay={true} muted loop>
          <source src={video} type="video/mp4"/>
        </video> 
      } 
    </div>


    <div className='header-text'>
      <div className='header-title'>
        <p>
          Automate and accelerate your
          <span style={{color: '#ffdc32', fontWeight: 'bold'}}>
            &nbsp;company&nbsp;
          </span> 
          with our 
          <span style={{color: '#00ccbe', fontWeight: 'bold'}}>
            &nbsp;developers network&nbsp;
          </span> 
        </p>
      </div>
      
      <div className='header-subtitle'>
        <p>
          We are digital innovators bringing you design and 
          technology to the mix. Try <span> SandPalace</span> now 
          and work with the smartest and most efficient teams in LatAm.
        </p>
      </div>
    </div>

    <Tags/>

    <div className='header-action'>        
      <Link to='/contact'>
        <button>Contact sales&nbsp;&nbsp;
          <span>
            <i className='fa fa-arrow-right'/>
          </span>
        </button>
      </Link>
    </div>

    <Shade/>

  </div>
}

export default Header;
