import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar(props) {

  const { navbar } = props;

  return <div className={ 'navbar' }>
    <Link 
      key='navbar-logo'
      to={{ pathname: '/' }}
      style={{ textDecoration: 'none' }}
    >
      <button className='navbar-logo'>
        <span>Sand</span>Palace
      </button>
    </Link>
  </div>
}

export default Navbar;