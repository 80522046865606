import React from 'react';

import { Link } from 'react-router-dom';

import './Tools.css';
// "AWS", "Android", "Angular", "ArcGIS", "Azure", "Excel", "GCP", "GIT", "Hadoop", "IOS", "Kanban", "MicroStrategy", "Node", "OpenCV", "PowerBI", "Python", "QGIS", "R", "React", "SCRUM", "Scala", "Snowflake", "Spark", "Tableau", "Tensorflow"
const tools = [
  {"symbol": '/logos/python.png', "name": "Python", "backgroundColor": "#2bc253", "textColor": "white", tool: "Python"},
  {"symbol": '/logos/react.png', "name": "React", "backgroundColor": "#333", "textColor": "#fff", tool: "React"},
  {"symbol": '/logos/angular.png', "name": "AngularJS", "backgroundColor": "#cc4b21", "textColor": "white", tool: "AngularJS"},
  {"symbol": '/logos/hadoop.png', "name": "Hadoop", "backgroundColor": "#ede732", "textColor": "#fff", tool: "Hadoop"},
  {"symbol": '/logos/spark.png', "name": "Spark", "backgroundColor": "#6bb7ed", "textColor": "white", tool: "Spark"},
  {"symbol": '/logos/scala.png', "name": "Scala", "backgroundColor": "#cc4b21", "textColor": "white", tool: "Scala"},
  {"symbol": '/logos/r.png', "name": "R", "backgroundColor": "#6bb7ed", "textColor": "white", tool: "R"},
  {"symbol": '/logos/aws_white.png', "name": "AWS", "backgroundColor": "#333", "textColor": "#fff", tool: "AWS"},
  {"symbol": '/logos/git.png', "name": "Git", "backgroundColor": "#333", "textColor": "#fff", tool: "Git"},
  {"symbol": '/logos/opencv.png', "name": "OpenCV", "backgroundColor": "#333", "textColor": "#fff", tool: "OpenCV"},
  {"symbol": '/logos/snowflake.png', "name": "Snowflake", "backgroundColor": "#333", "textColor": "#fff", tool: "Snowflake"},
  {"symbol": '/logos/tensorflow.png', "name": "Tensorflow", "backgroundColor": "#cc4b21", "textColor": "white", tool: "Tensorflow"},
  {"symbol": '/logos/qgis.png', "name": "QGIS", "backgroundColor": "#2bc253", "textColor": "white", tool: "QGIS"},
  {"symbol": '/logos/tableau.svg', "name": "Tableau", "backgroundColor": "#333", "textColor": "#fff", tool: "Tableau"},
  {"symbol": '/logos/powerbi_white.png', "name": "Power BI", "backgroundColor": "#333", "textColor": "#fff", tool: "Power BI"},
  {"symbol": '/logos/excel.png', "name": "Excel", "backgroundColor": "#2bc253", "textColor": "white", tool: "Excel"}
]

function Tools() {

  const [mobil, setMobil] = React.useState(false);

  // React.useEffect(() => {
  //   changeBackground();
  // }, [])

  function changeBackground() {
    if (window.innerWidth <= 641) {
      setMobil(true);
    } else {
      setMobil(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  let options = {
    size: mobil ? 80 : 200,
    minSize: mobil ? 40 : 100,
    gutter: 0,
    provideProps: false,
    numCols: mobil ? 3 : 6,
    fringeWidth: mobil ? 40 : 80,
    yRadius: mobil ? 160 : 140,
    xRadius: mobil ? 120 : 240,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 2
  }

  function DummyBubble(props) {
    return <div className="dummyBubble-container">
      <Link 
        to={{
          pathname: '/contact',
          search: `?tool=${props.data.tool}`
        }}
        style={{ textDecoration: 'none' }}
      >
        <button className="dummyBubble">
          <img src={process.env.PUBLIC_URL + props.data.symbol} alt="" />
          <p className="dummyBubbleText">
            {props.data.name}
          </p>
        </button>
      </Link>
    </div>
  }

  let dummyBubbles = [];
  tools
    .sort((a, b) => (a.name > b.name) ? 1 : -1)
    .map((data, i) => {dummyBubbles.push(
      <DummyBubble 
        data={data} 
        key={i} 
      />
    )});

  const Figures = () => {
    return <div className='tools-figures'>
      <div className='tools-dots'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile.png"} alt="yellow-dots"/>
      </div>
      <div className='tools-dots2'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile2.png"} alt="yellow-dots"/>
      </div>
      <div className='tools-dots3'>
        <img src={process.env.PUBLIC_URL + "/media/Masterfile1.png"} alt="yellow-dots"/>
      </div>
      <div className='tools-square0'/>
      <div className='tools-square1'/>
      <div className='tools-square2'/>
      <div className='tools-square3'/>
      <div className='tools-square4'/>
      <div className='tools-square5'/>
    </div>
  }

  return <div id='tools' className='tools'>
    <Figures />

    <div className='tools-title'>· Our tools ·</div>
    
    <div className='tools_box'>
      {dummyBubbles}
    </div>
  </div>
}

export default Tools;
