import React, { useState } from 'react';
import './Menu.css';

function Menu() {
  return <div className='menu'>
    Menu
  </div>
}


export default Menu;