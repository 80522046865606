import React, { useState, useEffect, useRef } from 'react';

import './Dropdown.css';

function cleanString(str) {
  if ( str ) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
  }
}

const Dropdown = props => {
  const [cursor, setCursor] = useState(0);
  const [arrows, setArrows] = useState({
  	up: false, down: true
  });
  const [wrapperPos, setWrapperPos] = useState({});
  const [menuPos, setMenuPos] = useState({});
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState(JSON.parse(JSON.stringify(props.options)));

  const handleKey = e => {
  	// Positions
  	var menuName = `contact-menu-${props.name}`;
  	var currentOptionName = `option-${props.name}-${cursor}`;
  	
  	var menuEl = document.getElementById(menuName);
  	var currentEl = document.getElementById(currentOptionName);
  	
  	if ( menuEl && currentEl ) {
  		var menuElPos = menuEl.getBoundingClientRect();
  		var currentElPos = currentEl.getBoundingClientRect();

  		var menu = (menuElPos.top + menuElPos.bottom) / 2;
  		var current = (currentElPos.top + currentElPos.bottom) / 2;

  		// Options
	    if (e.key === 'Escape') {
	      setShow(false);
	    } else if ( e.key === 'ArrowUp' ) {
	      if ( show && cursor > 0 ) {
	        setCursor(cursor - 1)
		     //  if ( options.length > 5 && current < menu && cursor > 1 ) {
		     //  	setPos(pos + 40);
		    	// }
	      }
	    } else if ( e.key === 'ArrowDown' ) {
	      if ( show && cursor < options.length-1 ) {
	        setCursor(cursor + 1);
		     //  if ( options.length > 5 && current > menu && cursor + 1 < options.length-1 ) {
		     //  	setPos(pos - 40);
		    	// }
	      } 
	      if ( !show && focus ) {
	      	setShow(true);
	      	setCursor(0);
	      }
	    } else if ( e.key === 'Enter' ) {
	      if ( show ) {
	        setShow(false);
	        props.onChange(options[cursor]);
	      }
	    }
  	}
  }

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShow(false);
        }
      }
      
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, cursor, show, options]);
  }

  function usePosition(ref) {
    useEffect(() => {
      function handleScroll(event) {
      	var menuName = `contact-menu-${props.name}`
      	var firstName = `option-${props.name}-0`
      	var lastName = `option-${props.name}-${options.length - 1}`
      	
      	var menuEl = document.getElementById(menuName);
      	var firstEl = document.getElementById(firstName);
      	var lastEl = document.getElementById(lastName);

        if ( ref.current && menuEl && firstEl && lastEl ) {
        	var menuPos = menuEl.getBoundingClientRect();
        	var firstPos = firstEl.getBoundingClientRect();
        	var lastPos = lastEl.getBoundingClientRect();
         	var offset = 10;

          if ( firstPos.top + offset < menuPos.top && lastPos.bottom - offset > menuPos.bottom ) {
          	setArrows({ up: true, down: true });
          } else if ( firstPos.top + offset < menuPos.top && lastPos.bottom - offset <= menuPos.bottom ) {
          	setArrows({ up: true, down: false });
          } else if ( firstPos.top + offset >= menuPos.top && lastPos.bottom - offset > menuPos.bottom ) {
          	setArrows({ up: false, down: true });
          } else {
          	setArrows({ up: false, down: false });
          }
        }
      }
      
      document.addEventListener("wheel", handleScroll);
      return () => {
      	document.removeEventListener("wheel", handleScroll);
      };
    }, [ref, options]);
  }

	const inputRef = useRef(null);
  useOutside(inputRef);

  const menuRef = useRef(null);
  usePosition(menuRef);

	return <div 
		ref={inputRef} 
		className='contact-input'
		onKeyDown={handleKey}
	>
		<button 
    	onClick={() => setShow(!show)}
    	style={{borderRadius: '20px'}}
  	>{props.value}</button>

  	<div 
			id={`contact-menu-${props.name}`}
			style={{
				left: props.posX === 'left' ? '-10px' : '',
				right: props.posX === 'right' ? '0px' : '',
				top: props.posY === 'bottom' ? '68px' : '',
				bottom: props.posY === 'top' ? '40px' : '',
				height: options.length < 5 ? `${options.length * 44}px` : '200px',
				display: show ? 'block' : 'none'
			}}
			className='contact-menu'
		>	
			<div 
				className='contact-menu-arrow-up-wrapper'
				style={{ display: arrows.up ? 'block' : 'none' }}
			>
				<span className='contact-menu-arrow-up'>
		      <i className='fas fa-caret-up' />
				</span> 
			</div>

			<div 
				className='contact-menu-arrow-down-wrapper'
				style={{ display: arrows.down ? 'block' : 'none' }}
			>
				<span className='contact-menu-arrow-down'>
	      	<i className='fas fa-caret-down' />
				</span> 
			</div> 
			
			<div 
				id={`contact-menu-ul-${props.name}`}
				className='contact-menu-ul'
				ref={menuRef} 	
			>
				{options.map((option, i) => {
					return <button 
						index={i}
						id={`option-${props.name}-${i}`}
						key={`option-${props.name}-${i}`}
						className={ i === cursor ? 
							'contact-option active' : 
							'contact-option'
						}
						name={props.name}
						value={option}
						onClick={e => {
							setShow(false);
							props.onChange(e.target.value);
						}}
						onMouseEnter={e => setCursor(
			      	parseInt(e.target.getAttribute('index')) 
			      )}
						onMouseLeave={e => setCursor(0)}
					>{option}</button>
				})}	
			</div>

		</div>
		
	</div>
}
// && pos > ((options.length-5) * -40) - 200
export default Dropdown;